.header {

  $parent: &;
  $left-gap: 125px;

  position: relative;

  min-height: 480px;
  margin-bottom: 80px;

  overflow: hidden;

  margin-left: $left-gap;
  padding-right: $left-gap;

  color: #fff;

  @include media(tablet) {
    min-height: unset;
    margin-left: 0;
    padding-right: 0;
    margin-bottom: 32px;
  }

  &--text-dark {

    #{$parent}__back {
      color: rgba(0, 0, 0, 0.7);

      &:before {
        @include icon-arrow;
      }

      &:after {
        background-color: rgba(0, 0, 0, 0.15);
      }

    }

    #{$parent}__text {
      color: rgba(0, 0, 0, 0.8);
    }

  }

  &__inner {
    padding-top: 56px;
    padding-bottom: 76px;

    @include media(tablet) {
      padding-top: 0;
      padding-bottom: 64px;
    }

  }


  &__back {

    position: relative;
    display: flex;
    align-items: center;
    min-height: 48px;
    margin-bottom: 51px;

    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;

    @include media(tablet) {
      margin-bottom: 32px;
    }


    &:after {
      content: "";

      position: absolute;
      display: block;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: calc(-100% + 148px);
      background-color: #fff;

      @include media(mobile) {
        left: -15px;
        width: calc(100% + 30px);
      }

    }

    &:before {

      content: "";
      @include icon-arrow-white();
      transform: rotate(90deg);
      margin-top: -1px;
      margin-right: 5px;

    }

  }

  &__heading {

    margin-left: -10px;

    @include media(mobile) {
      text-align: center;
    }

  }


  &__text {
    font-size: 21px;
    font-weight: 300;
    letter-spacing: 0.4px;
    line-height: 32px;

    @include media(tablet) {
      font-size: 19px;
      line-height: 28px;
    }

    @include media(mobile) {
      text-align: center;
    }

    &--thin {
      max-width: 460px;

      @include media(mobile) {
        margin-left: auto;
        margin-right: auto;
      }

    }

  }

  &__bg {

    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3F3F3F;
    width: 100%;
    height: 100%;

    //  animation: header-bg 3s;

  }

  &--bg-image {

    #{$parent}__heading {

      font-size: 128px;
      line-height: 128px;
      letter-spacing: -6.92px;
      text-align: left;


      @include media(tablet){
        font-size: 80px;
        line-height: 100px;
      }

      @include media(mobile){
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -1.74px;
        margin-top: 176px;
      }

    }

    #{$parent}__bg {

      $max-width: 1885px;

      background-size: cover;
      background-position: center 50%;
      background-repeat: no-repeat;

      max-width: $max-width;
      left: calc(50% - #{$max-width/2});

      @media (max-width: $max-width+$left-gap+$menu-toggle-width) {
        left: 0;
      }

      @include media(mobile){
        background-position: 60%;
      }

    }


  }

}

@keyframes header-bg {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}