.dotted-list {

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include media(mobile){
      flex-direction: column;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

  }

  &__icon {
    flex: 0 0 39px;
    height: 39px;
    margin-right: 54px;

    @include media(mobile){
      margin-right: 0;
      margin-bottom: 16px;
    }

  }

  &__content {
    color: rgba(0,0,0,0.7);
    font-size: 19px;
    font-weight: 300;
    letter-spacing: -0.4px;
    line-height: 28px;
  }

}