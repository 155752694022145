/* Responsive */

.desktop-only {
  @include media(tablet) {
    display: none !important;
  }
}

.tablet-down {
  @include media(tablet-up) {
    display: none;
  }
}


/* Tablet */
@include media(tablet) {

  .tablet-hide {
    display: none;
  }

  .tablet-invisible {
    visibility: hidden;
  }

}

/* Mobile */

@include media(mobile) {

  .mobile-hide {
    display: none;
  }

  .mobile-invisible {
    visibility: hidden;
  }

  .mobile-fw {
    width: 100%;
    padding: 0;
  }

}



/* Colors */

.bg-blue {
  background-color: $c-blue;
}

.bg-pink {
  background-color: $c-pink;
}

.bg-green {
  background-color: $c-green;
}

.bg-purple {
  background-color: $c-purple;
}

/* Spaces */

$spaces: (
        'lg' : (160, 64),
        'md' : (80, 32),
        'sm' : (40, 24)
);

$sides: (top, bottom);

@each $space_name, $space_values in $spaces {

  @each $side in $sides {

    .space-#{$side}-#{$space_name} {
      margin-#{$side}: #{nth($space_values, 1)}px;

      @include media(tablet) {
        margin-#{$side}: #{nth($space_values, 2)}px;
      }
    }
  }

}

/* Alignments */

.mobile-center {

  @include media(mobile) {
    text-align: center;
  }

}