.quote-list {

  $parent: &;

  padding: 86px 0 120px;
  
  @include media(tablet){
      padding: 32px 0 64px;
  }

  &__head {

    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__heading {
    position: absolute;
    max-width: 540px;
    right: 100px;

    @include media(tablet){
      max-width: unset;
      left: 0;
      right: 0;
      text-align: center;
    }

  }

  &__icon {
    max-width: 461px;

    @include media(tablet){
      max-width: 237px;
    }
  }

  &__content {

    max-width: 605px;
    margin-top: -48px;

    @include media(tablet){
      margin-top: 32px;
    }

  }

  &__item {
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 32px;

    @include media(mobile){
      font-size: 19px;
    }

    &:not(:last-child) {
      padding-bottom: 40px;

      @include media(mobile){
        padding-bottom: 32px;
      }

    }

    &:first-child {
      max-width: 465px;
    }

    &:before {

      content: "";
      display: block;
      height: 2px;
      width: 24px;
      background-color: $c-green;
      margin-bottom: 8px;

    }

  }

  &--blue {

    #{$parent}__item {
      &:before {
        background-color: $c-blue;
      }
    }

  }


}