.book-intro {

  display: flex;
  margin-bottom: 217px;

  @include media(tablet) {
    flex-direction: column;
    margin-bottom: 64px;
  }

  &__col {

    &--first {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 53.5%;

    }

    &--second {
      padding-left: 29px;
      width: 46.5%;

      @include media(tablet) {
        padding: 24px 15px 0;
      }

    }

    @include media(tablet) {
      width: 100%;
    }

  }

  &__download {

    padding: 20px 62px;
    margin-bottom: -26px;
    z-index: 1;

    border: none;
    background-color: #FFFFFF;
    box-shadow: -10px 5px 8px -10px rgba(189, 189, 189, 0.2), 10px 5px 8px -10px rgba(189, 189, 189, 0.2), 0 25px 20px -16px rgba(189, 189, 189, 0.4);

    color: rgba(0, 0, 0, 0.7);
    font-family: "Gotham Pro";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;

  }

  &__img-wrap {

    width: 100%;

    flex-grow: 1;
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, #FAFAFA 100%);

    overflow: hidden;

    @include media(tablet) {
      border-radius: 0;
    }

  }

  &__img {
    display: block;
    margin: 0 auto;
    max-width: 540px;

    @include media(mobile) {
      transform: scale(1.2);
      margin-top: 50px;
      margin-bottom: 50px;
    }

  }

  &__text {

    .lead {
      @include media(tablet){
        margin-bottom: 12px;
      }
    }

  }

  &__sub-heading {
    margin-top: 24px;
    margin-bottom: 16px;

    @include media(mobile) {
      margin-top: 32px;
    }
  }

  &__drop-down-group {
    display: flex;

    @include media(mobile) {
      flex-direction: column;
    }

  }

  &__drop-down {

    flex: 1 0;

    &:not(:last-child) {
      margin-right: 71px;
    }

    @include media(mobile) {
      margin-right: 0 !important;
      margin-bottom: 24px;
    }

  }

}