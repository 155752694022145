.card {

  display: flex;

  &__image {

    width: 100px;
    height: 100px;

    margin-right: 24px;

    &--icon {
      box-sizing: content-box;
      width: 56px;
      height: 56px;
      padding: 22px;

      @include media(tablet){
        width: 45px;
        height: 45px;
      }

    }

  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 17px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.14px;
    margin-bottom: 8px;

    @include media(tablet){
      font-size: 19px;
    }

  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.12px;

    @include media(tablet){
      font-size: 17px;
      font-weight: 300;
      text-align: center;
    }
  }

}