.tag-box {


  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;
  }

  &__item {

    display: flex;
    align-items: center;
    padding-bottom: 16px;

    color: rgba(0, 0, 0, 0.7);
    font-size: 19px;
    font-weight: 300;
    letter-spacing: -0.4px;
    
    @include media(mobile){
      font-size: 14px;
    }

    &:not(:nth-last-child(-n+2)):after {

      content: "";

      width: 1px;
      height: 10px;
      margin: 0 12px;
      background-color: rgba(0, 0, 0, .15);

    }

    &:last-child {
      margin-left: 24px;
    }

    &--italic {
      font-style: italic;
    }

    &--remove-line {

      &:after {
        opacity: 0;
      }

    }

  }

}