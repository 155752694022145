.subscribe-form {

  $parent: &;

  max-width: 350px;
  margin: 32px auto 0;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
  }


  &__input {

    min-height: 48px;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-right: 20px;
    background: transparent;
    outline: none;
    transition: 0.2s;

    font-family: "Gotham Pro";
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.32px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }

    &:hover,
    &:focus {
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }

  }

  &__btn {
    position: absolute;
    right: -10px;
    padding: 10px;
    transform: rotate(-90deg);
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
  }

  &__loader {

    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    opacity: 0;

  }

  &__response {

    display: none;
    margin-top: 10px;
    color: #5dd25d;
    transition: 0.2s;

  }

  &--error {

    #{$parent}__response {
      color: #e88181;
    }

  }

  &--loading {

    #{$parent}__input {
      padding-right: 75px;
    }

    #{$parent}__loader {
      opacity: 1;
    }

  }

}