.sidebar {

  background: linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%);
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: $menu-toggle-width;

  max-width: 920px;
  width: 100%;
  padding: 25px 32px 30px;
  overflow-y: auto;

  transition: transform 0.8s;
  transform: translateX(-100%) translateX(-40px);
  -webkit-backface-visibility: hidden;


  @include media(tablet) {

    max-height: calc(100% - 80px);

    left: unset;
    top: 63px;
    //bottom: 15px;
    bottom: unset;
    padding: 20px 15px;
    right: 0;
    max-width: 600px;
    transform: translateX(100%);
  }

  @include media(mobile){
    max-width: 345px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  &--active {
    transform: translateX(0px);
  }

  &__row {

    &--columns {
      display: flex;

      @include media(tablet) {
        flex-direction: column;
      }

    }

    &--bottom {
      margin-top: auto;
      display: none;

      @include media(tablet){
        display: none;
      }

    }

  }

  &__read-more {
    max-width: 404px;
    width: 100%;

    @include media(tablet) {
      max-width: 100%;
    }

    &:first-child {
      margin-right: 48px;
    }

  }

  &__menu {

    margin-top: 64px;
    margin-bottom: 76px;

    @include media(tablet){
      margin-top: 32px;
      //margin-bottom: 54px;
      margin-bottom: 20px;
    }

  }

  &__line {
    margin-top: 16px;
    margin-bottom: 24px;
  }


}