
.drop-down {

  $parent: &;

  position: relative;

  &__placeholder {

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    color: rgba(0, 0, 0, 0.7);
    font-family: "Gotham Pro";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;

    transition: 0.2s;

    &:hover {
      border-color: $c-pink;
    }

    &:after {

      content: "";
      margin-top: -2px;
      transition: 0.5s;
      @include icon-arrow;

    }

  }

  &__options {

    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 8px);
    display: none;
    padding: 8px 0;

    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #fff;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15), 0 28px 21px -20px rgba(0, 0, 0, 0.49);
    z-index: 9;

  }

  &__option {

    position: relative;
    display: flex;
    padding: 8px 15px;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Gotham Pro";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 16px;
    cursor: pointer;

    transition: 0.2s;

    &:before {

      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;

      content: "";
      height: 30px;
      width: 2px;
      background-color: #FD89C5;

    }

    &:hover {

      color: $c-pink;

      &:before {
        opacity: 1;
      }

    }

  }

  &--open {

    #{$parent}__placeholder {

      border-color: $c-pink;

      &:after {
        transform: rotate(180deg);
      }

    }

  }

  &--links {

    #{$parent}__option {

      padding: 0;

      a {
        flex-grow: 1;
        padding: 8px 15px;
        text-decoration: none;
      }

    }

  }



}