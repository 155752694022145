.accordion {

  $parent: &;

  margin-bottom: 120px;

  @include media(tablet){
    margin-bottom: 64px;
  }

  @include media(mobile){
    margin-bottom: 32px;
  }

  &__item {

    padding: 18px 20px 18px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    transition: 0.2s;
    
    @include media(mobile){
      padding-right: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:not(&--active):hover {
      border-color: $c-green;
    }

    &--active {
      #{$parent}__head {
        &:after {
          opacity: 0;
        }
      }
    }

  }

  &__head {

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 20px;
    letter-spacing: -0.6px;
    cursor: pointer;

    &:after {

      content: "";
      @include icon-plus-big-green;

    }

    &:hover {

      &:after {
        @include icon-plus-big-green-hover;
      }

    }


  }

  &__content {

    margin-top: 8px;

    color: rgba(0, 0, 0, 0.75);
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.14px;
    line-height: 24px;

  }


}