.post {

  &__title {

    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.89px;
    line-height: 40px;

    @include media(tablet) {
      margin-bottom: 8px;
      font-size: 24px;
      letter-spacing: -0.67px;
      line-height: 32px;
    }

  }

  &__excerpt {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 19px;
    font-weight: 300;
    letter-spacing: -0.4px;
    line-height: 28px;

    @include media(tablet) {
      margin-bottom: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.12px;
      line-height: 20px;
    }
  }

}