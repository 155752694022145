.locations {

  $parent: &;

  padding-top: 80px;
  padding-bottom: 120px;

  overflow: hidden;

  @include media(tablet){
    padding-top: 32px;
    padding-bottom: 32px;
  }


  &__heading {
    @include container();
    margin-bottom: 56px;
    color: #fff;
    
    @include media(tablet){
      margin-bottom: 24px;
    }

    @include media(mobile){
      text-align: center;
    }

  }


  &__inner {
    @include container(lg);

    display: flex;
    // align-items: center;
  }

  &__list {

    padding-left: 23px;
    min-width: 270px;
   // margin: 27px 0;

    //max-height: 475px;

    border-left: 1px solid rgba(0, 0, 0, .4);

    @include media(mobile){
      flex-grow: 1;
      border-left: none;
      padding-left: 0;
      margin: 0;
    }

  }

  &__list-item {

    padding: 12px 8px;
    transition: 0.2s;

    cursor: default;

    &:hover,
    &--active {
      background-color: #37DED3;
      color: #fff;
    }

  }

  &__title {

  }

  &__description {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.12px;
    line-height: 20px;
  }

  &__map {

    flex-grow: 1;
    margin-left: -70px;
    max-height: 503px;

    @include media(mobile){
      display: none;
    }

  }

  &__map-image {

    height: 100%;

    @include media(tablet){
      width: 120%;
    }

    .st0 {
      opacity: 0.4;
      fill: #4E4E4E;
      enable-background: new;
    }

  }

  &__map-dot {

    transition: 0.3s;

    &--active {
      fill: #fff;
      fill-opacity: 1;
    }

  }

  /* Scrollbar */

  .simplebar-vertical {

    right: unset;
    left: 0;

    .simplebar-scrollbar {

      left: -2px;
      width: 3px;

      &:before {
        opacity: 1;
      }

    }

  }


}