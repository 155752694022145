.numbered-list {

  $parent: &;
  $border: 1px solid rgba(0, 0, 0, .15);

  overflow: hidden;
  margin-left: -2px;

  &__inner {

    display: flex;
    flex-wrap: wrap;
    margin-right: -2px;
    margin-bottom: -2px;

    @include media(tablet) {
      flex-direction: column;
      margin-top: -23px;
      margin-bottom: 0;
    }

  }

  &__item {

    width: 50%;
    border-right: $border;

    @include media(tablet) {
      width: 100%;
      border-right: none;
    }

  }

  &__head {

    position: relative;

    display: flex;
    align-items: center;

    padding: 23px;

    border-bottom: $border;


    @include media(tablet) {

      justify-content: center;

      padding: 23px 5px;
      width: 100%;

    }

  }

  &__number {

    font-size: 180px;
    font-weight: 500;
    line-height: 1;
    color: rgba(0, 0, 0, 0.1);

    @include media(tablet) {
      font-size: 160px;
    }

    &--green {
      color: rgba(57,230,218,0.25);
    }

    &--purple {
      color: rgba(218,163,255,0.25);
    }

  }

  &__heading {
    max-width: 317px;
    width: 100%;
    margin-left: -65px;
  }


  &--inline {

    #{$parent}__inner {

      margin-left: -15px;
      margin-right: -15px;

      @include media(tablet){
        margin-bottom: 0;
      }

    }

    #{$parent}__item {

      width: 25%;
      border-right: none;

      @include media(tablet) {
        width: 100%;
      }

    }

    #{$parent}__head {


      @include media(tablet-up) {
        border-bottom: none;
      }

      padding: 0 15px;

    }

    #{$parent}__number {

      font-size: 130px;

      @include media(tablet){
        font-size: 160px;
      }

    }

    #{$parent}__heading {
      margin-left: -35px;
      max-width: 200px;
    }

  }


  &--has-content {

    #{$parent}__inner {
      margin-bottom: -67px;

      @include media(tablet){
        margin-bottom: -32px;
      }
    }

    #{$parent}__item {
      padding-bottom: 67px;

      @include media(tablet){
        padding-bottom: 32px;
      }
    }

    #{$parent}__head {
      padding: 0 25px;
    }

    #{$parent}__content {

      padding: 14px 25px;

      color: rgba(0,0,0,0.7);
      font-size: 19px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: -0.4px;
      
      @include media(tablet){
        text-align: center;
      }
      
      @include media(mobile){
        text-align: left;
      }

    }

    #{$parent}__heading {
      max-width: 225px;
    }

  }


}