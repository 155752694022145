.tweet {

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__date {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.12px;
    margin-bottom: 12px;

    &:after {
      content: "";
      display: block;
      width: 65px;
      height: 2px;
      background-color: #000;
      margin: 16px auto 0;
    }

  }

  &__content {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.75);
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.14px;
    line-height: 24px;
    margin-bottom: 16px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }

  &__link {
    opacity: 0.5;
    padding: 8px 0;
    margin-top: auto;

    color: rgba(0, 0, 0, 0.7);
    font-family: "Gotham Pro";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 16px;
    text-decoration: none;

    border-bottom: 1px solid transparent;
    transition: 0.2s;

    &:hover {
      opacity: 1;
      border-bottom-color: rgba(0, 0, 0, 0.15);
    }

  }

}