.posts {

  .posts__item {

    &:not(:last-child) {
      margin-bottom: 48px;

      @include media(tablet){
        margin-bottom: 24px;
      }
    }

  }

}