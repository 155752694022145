@mixin icon {
	background: url("../img/sprite-36cefe7b.svg") no-repeat;
}

@mixin icon-arrow {
	@include icon;
	background-position: 89.01098901098901% 98.36065573770492%;
	width: 10px;
	height: 7px;
}

@mixin icon-arrow-white {
	@include icon;
	background-position: 100% 98.36065573770492%;
	width: 10px;
	height: 7px;
}

@mixin icon-hamburger {
	@include icon;
	background-position: 38.27160493827161% 41.666666666666664%;
	width: 20px;
	height: 8px;
}

@mixin icon-minus-small-dark {
	@include icon;
	background-position: 7.407407407407407% 52.083333333333336%;
	width: 20px;
	height: 20px;
}

@mixin icon-minus-small-dark-hover {
	@include icon;
	background-position: 0 100%;
	width: 20px;
	height: 20px;
}

@mixin icon-minus-small-white {
	@include icon;
	background-position: 24.691358024691358% 100%;
	width: 20px;
	height: 20px;
}

@mixin icon-minus-small-white-hover {
	@include icon;
	background-position: 49.382716049382715% 100%;
	width: 20px;
	height: 20px;
}

@mixin icon-plus-big-green {
	@include icon;
	background-position: 7.894736842105263% 0;
	width: 25px;
	height: 25px;
}

@mixin icon-plus-big-green-hover {
	@include icon;
	background-position: 40.78947368421053% 0;
	width: 25px;
	height: 25px;
}

@mixin icon-plus-big-white {
	@include icon;
	background-position: 73.6842105263158% 0;
	width: 25px;
	height: 25px;
}

@mixin icon-plus-small-dark {
	@include icon;
	background-position: 74.07407407407408% 100%;
	width: 20px;
	height: 20px;
}

@mixin icon-plus-small-dark-hover {
	@include icon;
	background-position: 100% 0;
	width: 20px;
	height: 20px;
}

@mixin icon-plus-small-white {
	@include icon;
	background-position: 100% 41.666666666666664%;
	width: 20px;
	height: 20px;
}

@mixin icon-plus-small-white-hover {
	@include icon;
	background-position: 100% 83.33333333333333%;
	width: 20px;
	height: 20px;
}

@mixin icon-quote-small-pink {
	@include icon;
	background-position: 70% 48.07692307692308%;
	width: 21px;
	height: 16px;
}

@mixin icon-side-menu {
	@include icon;
	background-position: 0 0;
	width: 6px;
	height: 48px;
}

