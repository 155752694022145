.tweets {

  overflow: hidden;
  margin-left: -2px;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -2px;
    margin-bottom: -2px;

  }

  &__item {

    width: 25%;
    padding: 24px 15px;

    border-right: 1px solid rgba(0, 0, 0, 0.05);


    &:first-child {
      @include media(mobile){
        padding-top: 0;
      }
    }

    @include media(tablet){
      width: 50%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    @include media(mobile){
      width: 100%;
      border-right: none;
    }

  }

}