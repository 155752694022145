.logo {

  max-width: 124px;

  &--underline {

    &:after {

      content: "";
      display: block;
      width: 74px;
      height: 1px;
      opacity: 0.3;
      background-color: #fff;
      margin-top: 22px;

    }

  }

  &__image {
    width: 100%;
  }

}