.read-more {

  $parent: &;

  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  min-height: 48px;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
  }

  &__icon {

    $icon: &;

    margin-left: auto;

    @include icon-plus-small-white;

    #{$parent}:hover & {
      @include icon-plus-small-white-hover;
    }

    &--dark {
      @include icon-plus-small-dark;

      #{$parent}:hover & {
        @include icon-plus-small-dark-hover;
      }
    }

    &--minus {

      @include icon-minus-small-white;

      #{$parent}:hover & {
        @include icon-minus-small-white-hover;
      }

      &#{$icon}--dark {
        @include icon-minus-small-dark;

        #{$parent}:hover & {
          @include icon-minus-small-dark-hover;
        }
      }

    }

  }

  &__text {
    letter-spacing: 0.32px;
    font-weight: 500;
  }


  &--dark {

    color: rgba(0,0,0,0.7);

    &:after {
      background-color: rgba(0,0,0,0.15);
    }

  }


}