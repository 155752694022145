.testimonials {

  display: flex !important;
  flex-direction: column;
  overflow: hidden;

  &__item {

    padding: 32px 40px 104px;

    border-right: 1px solid rgba(0,0,0,0.05);

    @include media(mobile){
      padding-bottom: 20px;
    }

  }

  &__quote-icon {
    margin-bottom: 29px;

    @include media(mobile){
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__head {

    display: flex;
    align-items: center;
    margin-bottom: 10px;

  }

  &__photo {
    max-width: 88px;
    max-height: 88px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__name {

    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.58px;
    line-height: 32px;

  }

  &__post {

    color: rgba(57, 57, 57, 0.5);
    font-family: "Gotham Pro";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: 20px;
    text-transform: uppercase;


  }

  &__content {

    font-weight: 300;
    letter-spacing: -0.14px;
    line-height: 24px;

  }

  /* OwlCarousel */

  .owl-stage-outer {
    margin-right: 2px;
  }

  .owl-dots {

    order: -1;
    margin-top: 0 !important;
    margin-bottom: 10px;


    .owl-dot {

      &.active {

        span {
          background-color: $c-pink;
        }

      }

      span {
        width: 6px;
        height: 6px;
        margin: 4px;
      }

    }

  }

}