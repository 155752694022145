@mixin asideHeightMedia() {
  @media (max-height: 861px) {
    @content;
  }
}

.home-aside {

  //min-width: 472px;

  display: flex;
  flex-direction: column;
  flex: 0 5 32%;
  background-color: #3D3D3D;

  @include media(tablet) {
    flex-basis: 100%;
  }


  &__section {

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 48px 32px 32px;
    color: #fff;

    @include media(tablet) {
      padding-left: 15px;
      padding-right: 15px;
    }


    &--top {
      // padding-bottom: 0;
      margin-bottom: 144px;

      @include media(tablet) {
        padding-top: 0;
        margin-bottom: 0;
      }

      @include asideHeightMedia() {
        margin-bottom: 0;
      }

    }

    &--bottom {
      padding-top: 32px;

      @include media(tablet) {
        padding-bottom: 9px;
      }

    }

  }


  &__row {

    &--top {
      margin-top: 24px;
    }

    &--bottom {
      margin-bottom: 40px;

      @include media(tablet) {
        margin-bottom: 73px;
      }

      @include asideHeightMedia() {
        margin-top: auto;
      }

    }

  }

  &__footer {
    color: rgba(255, 255, 255, 0.54);
    font-family: "Gotham Pro";
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 14px;
    text-align: justify;
    margin-top: auto;
    
    @include media(tablet){
      color: #FFFFFF;
    }

    @include asideHeightMedia() {
      margin-top: 0;
    }
    
  }

  /* Mixes */

  &__logo {
    margin-bottom: auto;

    @include asideHeightMedia() {
      margin-bottom: 0;
    }

  }

  &__card {

    $block: '.card';

    margin-bottom: 16px;

    @media (max-width: 1200px) {

      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;

      #{$block}__image {
        margin-right: 0;
        margin-bottom: 24px;

        &--icon {
          margin-bottom: 0;
        }

      }

      #{$block}__logo {
        margin-bottom: 20px;
      }

      #{$block}__title {
        text-align: center;
      }


    }

  }

}


.home-tile {

  $parent: &;
  $tile-padding: 20px;
  $tile-bottom-gap: 16px;

  position: relative;
  display: flex;
  flex: 1 0 68%;

  &__item {

    position: absolute;
    left: 0;
    padding-right: $tile-padding;
    box-shadow: inset -1px 0 0 0 rgba(220, 217, 217, 0.5), 8px 4px 30px -5px rgba(0, 0, 0, 0.11);
    background: #fff;

    &--first {
      width: 25%;
      padding-left: $tile-padding;
      height: calc(100% - #{$tile-bottom-gap*3});
      z-index: 4;
    }

    &--second {
      width: 50%;
      height: calc(100% - #{$tile-bottom-gap*2});
      padding-left: calc(25% + #{$tile-padding});
      padding-bottom: $tile-padding;
      z-index: 3;
    }

    &--third {
      width: 75%;
      height: calc(100% - #{$tile-bottom-gap});
      padding-left: calc(50% + #{$tile-padding});
      padding-bottom: $tile-padding*2;
      z-index: 2;
    }

    &--fourth {
      width: 100%;
      height: 100%;
      padding-left: calc(75% + #{$tile-padding});
      padding-bottom: $tile-padding*3;
      z-index: 1;
    }

  }

  &__wrap {
    position: relative;
    display: block;
    height: 100%;
  }


  &__line {

    @include flex-center();

    height: 8px;
    transition: 0.5s;

    i {
      opacity: 0;
      transform: scale(0);
      transition: inherit;
    }

  }

  &__content {

    position: absolute;

    top: 48%;

    color: rgba(0, 0, 0, 0.45);
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.14px;
    line-height: 24px;

    transition: 0.2s;

  }

  &__title {

    position: absolute;
    bottom: 36px;
    left: calc(100% - #{$tile-padding});
    transform: rotate(-90deg);
    transform-origin: left bottom;
    letter-spacing: -1.3px;

    transition: left 0.3s;

  }


  &__wrap:hover {

    #{$parent}__line {

      height: 72px;

      i {
        opacity: 1;
        transform: scale(1);
      }

    }

    #{$parent}__content {
      color: rgba(0, 0, 0, 0.75);
    }

    & + #{$parent}__title {
      left: calc(100% - #{$tile-padding + 15});
    }

  }

}


.home-tiles-mob {

  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.75);
  padding-bottom: 32px;


  &__item {

    display: flex;
    justify-content: space-between;
    padding: 32px 0 32px 15px;
    box-shadow: inset -1px 0 0 0 rgba(220, 217, 217, 0.5), 8px 14px 30px -5px rgba(0, 0, 0, 0.11);
    text-decoration: none;

  }

  &__wrap {

  }

  &__title {

    margin-bottom: 4px;

  }

  &__content {
    font-size: 17px;
    letter-spacing: -0.14px;
    margin-right: 15px;
  }

  &__line {

    @include flex-center();

    flex: 0 0 60px;
    min-height: 116px;

  }

}
