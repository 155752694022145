
.brand-grid {

  $border: 1px solid rgba(0, 0, 0, .15);

  overflow: hidden;
  position: relative;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -49px -2px -49px;

    @include media(mobile){
      margin: 0;
    }

  }

  &__item {

    flex-basis: (100%/3);
    padding: 0 61px;
    border-right: $border;

    @include media(mobile){
      flex-basis: 100%;
      padding: 0;
      border-right: 0;
    }

    &--offset {
      margin-right: 1%;

      @include media(mobile){
        margin-right: 0;
      }

    }

  }

  &__item-inner {

    height: 100%;

    border-bottom: $border;

    padding: 18px 0 29px 0;
    
    @include media(mobile){
        padding: 16px 0;
    }

  }

  &__title {

    position: relative;

    display: flex;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      background-color: $c-blue;
      width: 4px;
      height: 4px;
      left: -12px;
      margin-top: -3px;
    }

  }

  &__description {
    color: rgba(0,0,0,0.75);
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.14px;
    line-height: 24px;
  }

}