
@mixin media($media) {
  @if $media == tablet {
    @media (max-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $media == tablet-up {
    @media (min-width: $tablet-breakpoint + 1) {
      @content;
    }
  } @else if $media == tablet-only {
    @media (max-width: $tablet-breakpoint) and (min-width: $mobile-breakpoint + 1) {
      @content;
    }
  } @else if $media == mobile {
    @media (max-width: $mobile-breakpoint) {
      @content;
    }
  } @else if $media == mobile-up {
    @media (min-width: $mobile-breakpoint + 1) {
      @content;
    }
  }
}


@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}


@mixin container( $size: '') {

  $width: 950px;

  @if $size == lg {
    $width: 1170px;
  } @else if $size == md {
    $width: 1045px;
  } @else if $size == sm {
    $width: 760px;
  }

  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

}