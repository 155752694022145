.contact-block {

  $parent: &;
  $gap: 10px;

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -$gap;
    margin-right: -$gap;

    @include media(tablet){
      flex-direction: column;
      align-items: center;
    }

  }

  &__item {

    display: flex;
    flex-direction: column;
    padding-left: $gap;
    padding-right: $gap;

    @include media(tablet){
      align-items: center;


      &:not(:last-child) {
        margin-bottom: 40px;
      }

    }

  }

  &__title {

    letter-spacing: -0.48px;
    margin-bottom: 8px;

    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      margin-top: 10px;

      @include media(tablet){
        margin-left: auto;
        margin-right: auto;
      }

    }

  }

  &__primary-text {
    color: rgba(0, 0, 0, 0.75);
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -0.14px;
    margin-bottom: 8px;
  }

  &__secondary-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.12px;
    text-decoration: none;
    margin-bottom: 6px;
  }


  &__tab-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0,0,0,.15);
    list-style: none;

    @include media(tablet-up){
      display: none;
    }

  }

  &__tab-title {

    flex: 1 0;
    text-align: center;
    margin-bottom: -1px;
    padding: 7px 0;
    border-bottom: 2px solid transparent;
    transition: 0.4s;
    cursor: pointer;


    &--active {
      border-bottom: 2px solid rgba(0,0,0,0.7);
    }

  }

  &--tabs {

    #{$parent}__title {
      @include media(tablet){
        display: none;
      }
    }

  }

}