/* Gotham */

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Light.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Bold.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-BoldItalic.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Medium.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-MediumItalic.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-LightItalic.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-BlackItalic.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Italic.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro/GothamPro-Black.woff2') format('woff2'),
  url('../fonts/GothamPro/GothamPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* Sentinel */

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-Light.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-SemiboldItalic.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-Black.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel Book';
  src: url('../fonts/Sentinel/Sentinel-Book.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-Bold.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-LightItalic.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-MediumItalic.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-BlackItalic.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-BoldItalic.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-Semibold.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel Book';
  src: url('../fonts/Sentinel/Sentinel-BookItalic.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Sentinel';
  src: url('../fonts/Sentinel/Sentinel-Medium.woff2') format('woff2'),
  url('../fonts/Sentinel/Sentinel-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
