.logo-box {

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -16px -15px;

    @include media(tablet){
      margin: -12px -15px;
    }
  }

  &__item {
    box-sizing: content-box;
    max-width: 160px;
    padding: 12px 15px;

    @include media(tablet){
      max-width: 105px;
    }

  }

}