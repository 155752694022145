body {
  font-family: 'Sentinel';
  font-size: 17px;
  color: rgba(0,0,0,0.8);

  /* smartphones, touchscreens */
  @media (hover: none) and (pointer: coarse) {
    cursor: pointer;
  }

}

body.overflow,
html.overflow {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  background-color: #000000;
  opacity: 0;
  visibility: hidden;

  &--active {
    opacity: 0.7;
    visibility: visible;
  }

}

.container {
  @include container();
}

.container-lg {
  @include container(lg);
}

.container-md {
  @include container(md);
}

.container-sm {
  @include container(sm);
}


.main {

  @include media(tablet-up){
    margin-left: $menu-toggle-width;
  }

  &--home {
    display: flex;
    min-height: 100vh;

  }

}

.side-menu-toggle {

  $parent: &;

  display: flex;
  align-items: center;

  background-color: #3F3F3F;
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.08), 1px 0 2px 0 rgba(0, 0, 0, 0.15);

  cursor: pointer;
  z-index: 100;

  @include media(tablet-up){
    position: fixed;
    justify-content: center;
    width: $menu-toggle-width;
    height: 100vh;
  }
  
  @include media(tablet){

    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: $menu-toggle-tab-height;
    justify-content: space-between;
    padding: 0 15px;
    transform: translateY(-100%);
    transition: 0.8s;
    box-shadow: inset 0 -1px 0 0 rgba(255,255,255,0.08), 0 1px 2px 0 rgba(0,0,0,0.15);

  }


  &__logo {

    width: 97px;

    
    @include media(tablet-up){
      opacity: 0;
      position: absolute;
      transform: rotate(-90deg) translateX(200%);
      transform-origin: bottom right;
      transition: 0.8s;
      top: 10px;
      right: 9px;
    }
    
    @include media(tablet){
      margin-top: 4px;
    }

  }

  &__icon {

    display: block;
    @include icon-side-menu;

    @include media(tablet){
      @include icon-hamburger;
    }

  }

  &--active {

    #{$parent}__logo {
      opacity: 1;

      @include media(tablet-up){
        transform: rotate(-90deg) translateX(0);
      }
    }

    @include media(tablet){
      opacity: 1;
      transform: translateY(0);
    }

  }

  &--inner {

    @include media(tablet){
      position: static;
      transform: translateY(0);
    }

    #{$parent}__logo {

      @include media(tablet-up){
        opacity: 1;
        transform: rotate(-90deg) translateX(0);
      }

    }

  }



}

.mobile-menu-toggle {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
  z-index: 999;
}

.line {
  display: block;
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);

  &--width_lg {
    @include container(lg);
  }

  &--width_md {
    @include container(md);
  }


}

img {
  width: 100%;
  height: auto;
}


.underline-link {

  position: relative;
  display: inline-block;
  max-width: 100%;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: -1;
    opacity: 0.24;
    background-color: #000;
  }

  @each $name, $color in $accent-colors {

    &--#{$name} {
      &:after {
        background-color: $color;
      }
    }

  }

  &--theme_link {

    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 32px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include media(mobile){
      font-size: 17px;
    }

  }


}

.marked-text {

  position: relative;
  display: inline-block;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    height: 5px;
    z-index: -1;
    opacity: 0.2;
    background-color: #000;
  }

}


.bottom-bar {

  padding: 80px 20px;
  background-color: rgba(0,0,0,0.05);

  @include media(tablet){
    padding: 32px 0 64px;
  }

  &--text_center {
    text-align: center;
  }

  &--thin {
    padding-top: 64px;
  }

  /* Mixes */

  &__line {
    margin-top: 23px;
    margin-bottom: 24px;
  }

  &__contact-block {
    margin-top: 36px;
  }

}

.copyright {

  @include flex-center();


  padding: 9px 15px;

  background-color: #FAFAFA;
  box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.1);

  font-family: "Gotham Pro";
  color: rgba(0,0,0,0.8);
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: justify;

  @include media(tablet){
    color: rgba(0,0,0,0.8);
  }

}