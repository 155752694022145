.core-team {

  display: flex;
  justify-content: space-between;

  padding-bottom: 120px;
  margin: 0 -15px;

  @include media(mobile){
    flex-direction: column;
    padding-bottom: 61px;
  }

  &__item {

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 15px;


    @include media(mobile){
      flex-direction: row;
      justify-content: center;
      margin-bottom: 31px;
    }

  }

  &__image-wrap {
    max-width: 100px;
    margin-bottom: 10px;

    @include media(mobile){
      margin-bottom: 0;
      margin-right: 35px;
    }
  }

  &__image {
    width: 100%;
    border-radius: 50%;
  }

  &__bottom {
    text-align: center;
    @include media(mobile){
      min-width: 100px;
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.58px;
    line-height: 32px;
    margin-bottom: 4px;
  }

  &__post {
    font-family: "Gotham Pro";
    color: rgba(57,57,57,0.5);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

}