.icon-plus-big-white {
  display: block;
  @include icon-plus-big-white;
}

.icon-hamburger {
  display: block;
  @include icon-hamburger;
}

.icon-arrow {
  display: block;
  @include icon-arrow;
}

.icon-quote-small-pink {
  display: block;
  @include icon-quote-small-pink;
}