.menu {

  $parent: &;

  &__item {

    $item: &;

    display: flex;
    align-items: center;

    padding: 16px 0;

    font-family: "Gotham Pro";
    font-size: 56px;
    font-weight: bold;
    letter-spacing: -1.74px;
    line-height: 64px;
    text-decoration: none;

    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transition: 0.2s;

    @each $name, $color in $accent-colors {

      &--#{$name} {

        &:hover,
        &#{$item}--active {
          border-color: $color;
          color: $color;
        }

        @include media(tablet) {
          &:before {
            background-color: $color;
          }
        }
      }

    }

    @include media(tablet) {

      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.7px;
      padding: 8px 0;

      &:before {
        content: "";
        height: 28px;
        width: 4px;
        margin-right: 11px;
        margin-top: -5px;
      }

    }

  }


}