.heading {

  font-family: "Gotham Pro";
  font-weight: bold;
  font-size: 12px;

  &--1 {
    font-size: 148px;
    line-height: 156px;
    letter-spacing: -8px;
    
    @include media(tablet){
      font-size: 100px;
      line-height: 114px;
    }

    @include media(mobile){
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -1.74px;
    }
    
  }

  &--2 {

    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1.74px;
    
    @include media(tablet){
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.7px;
    }
    
  }

  &--3 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.3px;

    @include media(tablet){
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.7px;
    }

  }

  &--4 {
    font-size: 32px;
    letter-spacing: -0.7px;
  }

  &--4-static {
    font-size: 32px;
    letter-spacing: -0.7px;
  }

  &--5 {
    font-size: 20px;
    letter-spacing: -1px;
    
    @include media(tablet){
      font-size: 16px;
      letter-spacing: -0.48px;
    }
  }

  &--5-static {
    font-size: 20px;
    letter-spacing: -1px;
  }

  &--6 {
    font-size: 16px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--space_lg {

    margin-bottom: 80px;

    @include media(tablet){
      margin-bottom: 32px;
    }

  }

  &--space_md {
    margin-bottom: 64px;

    @include media(tablet){
      margin-bottom: 32px;
    }
  }

}


.lead {
  color: rgba(0,0,0,0.75);
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -0.14px;
  line-height: 24px;

  margin-bottom: 16px;
}
